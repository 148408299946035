<div *ngIf="auth.user$ | async; then authenticated else guest">
    <!-- template will replace this div -->
</div>

<!-- User NOT logged in -->
<ng-template #guest>
<h3>Howdy, GUEST</h3>
<p>Login to get started...</p>

<button (click)="auth.googleSignin()">
    <i class="fa fa-google"></i> Connect Google
</button>

</ng-template>


<!-- User logged in -->
<ng-template #authenticated>
<div *ngIf="auth.user$ | async as user">
    Howdy, {{ user.name }}
    <button (click)="auth.signOut()">Logout</button>
</div>
</ng-template>