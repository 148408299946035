<div *ngIf="auth.user$ | async as user">
    <form [formGroup]="postForm">
        <mat-form-field>
            Title:
            <input matInput type="text" formControlName="title" required>
            <mat-error *ngIf="postForm.invalid">Title is required</mat-error>
        </mat-form-field>
        <br/>
        <mat-form-field>
            Intro:
            <input matInput type="text" formControlName="intro" required>
            <mat-error *ngIf="postForm.invalid">Intro is required</mat-error>
        </mat-form-field>
        <br/>
        <angular-editor formControlName="body" [config]="editorConfig"></angular-editor>
        <mat-slide-toggle formControlName="publish"
            class="example-margin"
            color="primary"
            >
            Publish
        </mat-slide-toggle>
    </form>


    <div *ngIf="editPostId">
        <br/>
        <label for="file">File:</label>
        <input type="file" (change)="upload($event, user)" accept=".png,.jpg" />
        <progress max="100" [value]="(uploadProgress | async)"></progress>
        <br/>
    </div>

    <br/>
    <button mat-button (click)="save()">Save</button>
    <button mat-button (click)="onCancel()">Cancel</button>
    <p></p>

</div>



