<mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>{{postObj.title}}</mat-card-title>
      <mat-card-subtitle>Publish</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="{{photoUrl | async}}" alt="Photo of a Shiba Inu">
    <mat-card-content>
      <p>
        {{postObj.intro}}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!readonly" mat-button (click)="onEdit()">EDIT</button>
      <button *ngIf="!readonly" mat-button (click)="onDelete()">DELETE</button>
    </mat-card-actions>
  </mat-card>