<div *ngIf="auth.user$ | async as user" class="addPhotos">
    <button (click)="onNew()" mat-raised-button color="primary">Add Photo</button>
</div>

<h1>Photos</h1>

<div id="photos">
    <div class="photo" *ngFor="let photo of photoService.items | async ">
        <app-photo [photo]="photo.payload" (edit)="onEdit($event)" [thumb]="true"></app-photo>
     </div>
</div>
