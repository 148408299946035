<header>
    <mat-toolbar color="primary">
        <mat-menu #appMenu="matMenu">
            <button mat-menu-item routerLink="/myposts">
                <mat-icon>post_add</mat-icon>
                <span>My Posts</span>
            </button>
            <button mat-menu-item routerLink="/myphotos">
                <mat-icon>insert_photo</mat-icon>
                <span>My Photos</span>
            </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <a href="https://www.roorkee.org/" class="website-name">Roorkee.org</a>
        &nbsp; >
        <a routerLink="/home" class="website-name">My Account</a>
        <span class="toolbar-fill-remaining-space"></span>
        <div *ngIf="auth.user$ | async; then authenticated else guest">
            <!-- template will replace this div -->
        </div>
    </mat-toolbar>
</header>
<main>
    <router-outlet></router-outlet>
</main>
<footer>
    <mat-toolbar color="primary">
        <span class="toolbar-fill-remaining-space"></span>
        <a routerLink="/privacy">Privacy Policy</a>
        &nbsp;:&nbsp;
        <a routerLink="/disclaimer">Disclaimer</a>
    </mat-toolbar>
</footer>

<ng-template #guest>
    <button mat-icon-button>
        <mat-icon>account_circle</mat-icon>
    </button>
</ng-template>

<ng-template #authenticated>
    <div *ngIf="auth.user$ | async as user" >
        <span style="float: left; padding-right: 1em;">{{ user.name }}</span>
        <img [src]="user.photoURL" width="50" style="border-radius: 50%;">
    </div>
</ng-template>