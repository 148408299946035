<form [formGroup]="photoForm">
    <h1 *ngIf="editPhotoId; else newTitle">
        Edit photo
    </h1>
    <ng-template #newTitle>
        <h1>Upload new photo</h1>
    </ng-template>
    <mat-form-field>
        Title:
        <input matInput type="text" formControlName="title" required>
        <mat-error *ngIf="photoForm.invalid">Title is required</mat-error>
    </mat-form-field>
    <mat-form-field>
        Album:
        <input matInput type="text" formControlName="album">
    </mat-form-field>
    <mat-form-field>
        Description:
        <input matInput type="text" formControlName="description">
    </mat-form-field>
    <mat-slide-toggle formControlName="publish" color="primary">Publish</mat-slide-toggle>

    <app-photo *ngIf="photoObserver | async as photo" [photo]="photo" [readonly]="true" [size]="med"></app-photo>

    <label for="file">File:</label>
    <input type="file" (change)="onFileChanges($event)" accept=".png,.jpg" />
    <progress max="100" [value]="(uploadProgress | async)"></progress>
    
    <div>
        <button mat-button (click)="onSave()">Save</button>
        <button mat-button (click)="onCancel()">Cancel</button>
    </div>


</form>